<template>
	<div class="container-mobile" :class="{ 'mt-16': $isPwa() }">
		<!-- <pre>{{ expenses }}</pre> -->
		<div class="card" v-for="(expense, i) in expenses" :key="i">
			<div class="wrapper">
				<div class="header">
					<div class="first-col">
						<span>{{ expense.depName }}</span>
					</div>
					<div class="second-col d-flex justify-content-end">
						<div class="status info px-2" style="width: auto">
							<span>{{ expense.numExpForDep }}</span>
						</div>
					</div>
				</div>
			</div>
			<div v-for="(user, y) in expense.users" :key="y">
				<validate-expense-detail
					:user="user"
					:items="user.exp"
					:fields="fields"
					:default-toggle-detail="false"
					@validate-expense-detail:validated="validate"
					@validate-expense-detail:clicked="rowEdit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import ValidateExpenseDetail from './ValidateExpenseDetail';

export default {
	name: 'MobileViewsMainComponent',
	components: {
		ValidateExpenseDetail
	},
	props: {
		expenses: {
			type: Array,
			required: true,
			default: () => []
		},
		fields: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	methods: {
		validate(payload) {
			if (payload) {
				this.$emit('mobile-view-validate-expense:validated', payload);
			}
		},
		rowEdit(payload) {
			if (payload) {
				this.$emit('mobile-view-validate-expense:clicked', payload);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.container-mobile .card .wrapper .header .first-col span {
	font-weight: 600;
	font-size: 1rem;
	color: rgba(6, 38, 62, 0.74);
}

.container-mobile .card .wrapper .header .second-col .status span {
	font-size: 0.85rem;
}
</style>
